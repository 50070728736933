import InputGroupOrCollectionModel from "./InputGroupOrCollectionModel";
import References from "../../../util/References";
import Core from "../../../services/Core";
import { HistoryType } from "../../../services/HistoryStore";

export default class InputCollectionChildModel extends InputGroupOrCollectionModel {
  constructor(id, name) {
    super(
      {
        ui: {
          name,
          label: name,
        },
      },
      id
    );
    this.enabled = true;
    this.conditionsVisible = true;
  }

  import(value) {
    // need to check specifically for undefined, not false
    if (value["conditions-visible"] !== undefined) {
      this.conditionsVisible = value["conditions-visible"];
    }
  }

  getSuperType() {
    return References.INPUT_SUPER_TYPE.COLLECTION_CHILD;
  }

  getUiData() {
    const parentNode = Core.getDeepNodeById(this.getParentId(), false);
    let visibilityToggleable = parentNode.instance.visibilityToggleable;

    if (!visibilityToggleable) {
      return {
        ...super.getUiData(),
        visibilityToggleable,
        conditionsVisible: this.conditionsVisible,
      };
    }

    const visibilityToggledInputs = parentNode.inputs.filter(
      input => !input.instance.conditionsVisible && input.instance.id !== this.id
    );

    visibilityToggleable =
      parentNode.inputs.length > 1 && visibilityToggledInputs.length < parentNode.inputs.length - 1;

    return {
      ...super.getUiData(),
      visibilityToggleable,
      conditionsVisible: this.getConditionsVisible(),
    };
  }

  setConditionsVisible(value, persistHistory = true) {
    if (persistHistory) {
      this.updateHistory(HistoryType.Visibility, { value });
    }
    this.conditionsVisible = value;
    this.regenerateUiStore();
  }

  getConditionsVisible() {
    return this.conditionsVisible;
  }
}
