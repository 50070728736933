export const fitCanvasToContainer = (containerDimensions, adRatio) => {
  if (!containerDimensions) {
    return;
  }
  const { height } = containerDimensions;
  return computeBestFit({ width: height * adRatio, height }, containerDimensions);
};

export const processImageInput = value => {
  if (!value) {
    return null;
  }
  // if is bin image
  if (value.bin) {
    const imageMeta = value.bin.split(":");
    return `data:${imageMeta[1]};base64,${imageMeta[2]}`;
  }

  return value;
};

export const restrictCoordinates = (coordinates, bounds) => {
  if (!coordinates || !bounds) {
    return;
  }

  const newCoordinates = { ...coordinates };
  const newBounds = { ...bounds };
  if (!newBounds.x) {
    newBounds.x = 0;
  }

  if (!newBounds.y) {
    newBounds.y = 0;
  }

  if (newBounds.width === 0) {
    newCoordinates.x = newBounds.x;
  } else if (coordinates.x > newBounds.x + newBounds.width - coordinates.width) {
    newCoordinates.x = newBounds.x + newBounds.width - coordinates.width;
  } else if (coordinates.x <= newBounds.x) {
    newCoordinates.x = newBounds.x;
  }

  if (newBounds.height === 0) {
    newCoordinates.y = newBounds.y;
  } else if (coordinates.y > newBounds.y + newBounds.height - coordinates.height) {
    newCoordinates.y = newBounds.y + newBounds.height - coordinates.height;
  } else if (coordinates.y <= newBounds.y) {
    newCoordinates.y = newBounds.y;
  }

  return newCoordinates;
};

export const computeBestFit = (boxDimensions, containerDimensions) => {
  // calculate the best fit of the image inside current ad size
  const { width: boxWidth, height: boxHeight } = boxDimensions;
  const { width: containerWidth, height: containerHeight } = containerDimensions;
  const boxRatio = boxWidth / boxHeight;
  let computedWidth = boxWidth;
  let computedHeight = boxHeight;

  if (boxWidth > containerWidth) {
    computedWidth = containerWidth;
    computedHeight = computedWidth / boxRatio;

    if (computedHeight > containerHeight) {
      computedHeight = containerHeight;
      computedWidth = computedHeight * boxRatio;
    }
  } else if (boxHeight > containerHeight) {
    computedHeight = containerHeight;
    computedWidth = computedHeight * boxRatio;
    if (computedWidth > containerWidth) {
      computedWidth = containerWidth;
      computedHeight = computedWidth / boxRatio;
    }
  }

  return { width: computedWidth, height: computedHeight };
};

export const computeAdSize = (adUnitTechnicalId, adRatio) => {
  switch (parseFloat(adRatio)) {
    case 0.2666: // Banner 160x600
      return { width: 160, height: 600 };
    case 0.5: // Banner 300x600
      return { width: 300, height: 600 };
    case 0.6: // Interstitial, In-Article Reveal
      return { width: 1040, height: 1734 };
    case 0.6666: // Banner 320x480
      return { width: 320, height: 480 };
    case 0.8: // Interstitial, In-Article Reveal
      return { width: 1388, height: 1734 };
    case 1.2: // Banner 336x280, Banner 300x250
      return adUnitTechnicalId === "banner-300x250" ? { width: 300, height: 250 } : { width: 336, height: 280 };
    case 1.3333: // Thumbnail, In-Article MPU
      return { width: 720, height: 540 };
    case 1.7777: // Header, Footer, In-Article Landscape, Banner 640x360
      return adUnitTechnicalId === "banner-640x360" ? { width: 640, height: 360 } : { width: 1280, height: 720 };
    case 3: // Banner 300x100
      return { width: 300, height: 100 };
    case 3.2: // Footer, Banner 320x100
      return adUnitTechnicalId === "banner-320x100" ? { width: 320, height: 100 } : { width: 1280, height: 400 };
    case 3.88: // Banner 970x250
      return { width: 970, height: 250 };
    case 4.4: // Header, Footer, In-Article Landscape
      return adUnitTechnicalId === "header_ad" ? { width: 2640, height: 600 } : { width: 1760, height: 400 };
    case 6: // Banner 300x50
      return { width: 300, height: 50 };
    case 6.4: // Banner 320x50
      return { width: 320, height: 50 };
    case 8.0888: // Banner 728x90
      return { width: 728, height: 90 };
    case 10.7777: // Banner 970x90
      return { width: 970, height: 90 };

    // custom ratios, might not support pixel positioning correctly
    default:
      return { width: 1024 * adRatio, height: 1024 };
  }
};
export const restrictTransform = (container, canvasDimensions) => {
  const MIN_HEIGHT = 10;
  const MIN_WIDTH = 10;

  const computedBox = {
    ...container,
    x: Math.max(container.x, 0),
    y: Math.max(container.y, 0),
    width: Math.max(MIN_WIDTH, container.width),
    height: Math.max(MIN_HEIGHT, container.height),
  };

  if (computedBox.x + computedBox.width > canvasDimensions.width) {
    computedBox.width = Math.max(MIN_WIDTH, canvasDimensions.width - computedBox.x);
    computedBox.x = canvasDimensions.width - computedBox.width;
  }

  if (computedBox.y + computedBox.height > canvasDimensions.height) {
    computedBox.height = Math.max(MIN_WIDTH, canvasDimensions.height - computedBox.y);
    computedBox.y = canvasDimensions.height - computedBox.height;
  }

  return computedBox;
};
