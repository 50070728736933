import Api from "./Api";
import { CREDENTIALS, STORE } from "./Credentials";

export function isInternalAsset(assetUrl, experienceAssetsBaseUrl) {
  return assetUrl?.startsWith(experienceAssetsBaseUrl) === true;
}

export function buildOgyPlayerURL() {
  return "https://mwtw.presage.io/v7.3.2/ogy-loader.js";
}

export function buildAdFormatURL(adFormatId, adUnit) {
  function getEnvironmentSuffix() {
    if (process.env.REACT_APP_ENV === "prod") {
      return "prod";
    } else if (process.env.REACT_APP_ENV === "staging") {
      return "staging";
    }
    return "devc";
  }
  return `"https://creative-console-api.${getEnvironmentSuffix()}.cloud.ogury.io/ad-formats/${adFormatId}/preview.js?au=${adUnit}"`;
}

export function buildSdkUrl(applicationId = "preview") {
  let customerPublicId = "ogury";
  function getEnvironmentSuffix() {
    if (process.env.REACT_APP_ENV === "prod") {
      return "";
    } else if (process.env.REACT_APP_ENV === "staging") {
      return "-staging";
    }
    return "-dev";
  }
  const credentials = STORE[CREDENTIALS];
  if (credentials?.customerPublicId) {
    customerPublicId = credentials.customerPublicId;
  }
  return `https://f${getEnvironmentSuffix()}.motionly.presage.io/javascript/1/sdk/?customerId=${customerPublicId}&applicationId=${applicationId}`;
}

export function buildInternalAssetUrl(storagePath) {
  if (Api.ROUTES.API_URL.includes("localhost")) {
    return `${Api.ROUTES.API_URL}/gcs/${storagePath}/`;
  }
  return `https://storage.googleapis.com/${storagePath}/`;
}
export const CUSTOMER_ID = "5711737575702528";
export const FILE_PROTOCOL = "file://";
export const DEBUG_KEY = "ocsBuilderDebug";
export const NOTIFICATION_PLACEMENT = "bottomRight";
export const DBNAME = "dbPSD";
export const STORENAME = "PSDStore";
export const TRANSACTIONMODE = {
  RONLY: "readonly",
  RW: "readwrite",
};
export const NUMBER_OF_DAYS_TO_KEEP_PSD_FILES = 14;

// Whether the interface should be in debug mode, pres "ctrl + shift + d" for toggleing the debug.
export const IS_DEBUG = localStorage.getItem(DEBUG_KEY) === "true";
// Whether the inputs matching conditions should be logged
export const LOG_INPUTS_CONDITIONS = false;
// Whether web services stubs should be used
const FORCE_USE_STUBS = false;
// Whether stubs + API key should be used.
const FORCE_USE_API_KEY = false;
//use an API key instead of using only local stub
export const USE_API_KEY = FORCE_USE_API_KEY || process.env.REACT_APP_USE_API_KEY === "true";
// Only change the value of FORCE_USE_STUBS to use stubs without having to restart the server
export const USE_STUBS = FORCE_USE_STUBS || USE_API_KEY || process.env.REACT_APP_USE_STUBS === "true";

export const SHARED_GOOGLE_DRIVE_FOLDER_LINKS_PER_ENV = {
  development: "https://drive.google.com/drive/folders/0ACsd2UZOogPuUk9PVA",
  staging: "https://drive.google.com/drive/folders/0AKoyI9bcA4NfUk9PVA",
  prod: "https://drive.google.com/drive/folders/0AOV3Umefwo4zUk9PVA",
};
