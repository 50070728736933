import MotionlyApiClient from "@ogury/motionly-ws-api/MotionlyApiClient";

async function search(searchTerm, pageNumber, pageSize) {
  return MotionlyApiClient.lottieApi().search(searchTerm, pageNumber, pageSize);
}
async function downloadJson(uuid) {
  return MotionlyApiClient.lottieApi().downloadJson(uuid);
}

export default {
  search,
  downloadJson,
};
